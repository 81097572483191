import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdminCompanyTsUrl } from '../services/companies/companies.models';
import { CompaniesService } from '../services/companies/companies.service';
import { AuthorizationService } from './authorization/authorization.service';
import { ERoles } from './authorization/roles.enum';
import { NavigationHistoryService } from './navigation/navigation-history.service';
import { UserActivityService } from './user-activity/user-activity.service';

@Injectable({
    providedIn: 'root',
})
export class AppStateService {
    private _companyUrls$ = new BehaviorSubject<AdminCompanyTsUrl[]>([]);

    constructor(
        private authorizationService: AuthorizationService,
        private companiesService: CompaniesService,
        private userActivityService: UserActivityService,
        private navigationHistoryService: NavigationHistoryService
    ) {}

    get companyUrls$() {
        return this._companyUrls$.asObservable();
    }

    init() {
        this.authorizationService.activeCompany$.subscribe((activeCompany) => {
            this.userActivityService.start();
            this.getCompanyUrlsIfAllowedRole(activeCompany);
        });
        this.navigationHistoryService.init();
    }

    private getCompanyUrlsIfAllowedRole(activeCompany: string) {
        const allowedRoles = [ERoles.CompanyAdmin, ERoles.PaymentsManager, ERoles.Superuser];
        if (this.authorizationService.hasSomeRole(allowedRoles)) {
            this.companiesService.getCompanyUrls(+activeCompany).subscribe((data) => this._companyUrls$.next(data));
        }
    }
}
