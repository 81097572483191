import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoAuthGuard } from '@ridango/auth';
import { FuseLayoutComponent } from '@ridango/ui/fuse-layout';
import { AfterLoginComponent } from './core/after-login.component';
import { AuthenticationGuard } from './core/authentication.guard';
import { AuthorizedPreloadingStrategy } from './core/authorization/authorized-preloading';
import { PermissionGuard } from './core/authorization/permission.guard';
import { ERoles } from './core/authorization/roles.enum';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { Breadcrumbs, EAppRoutes } from './navigation.models';

export const routes: Routes = [
    {
        path: EAppRoutes.AfterAuth,
        component: AfterLoginComponent,
    },
    {
        path: 'login',
        component: FuseLayoutComponent,
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        data: { layout: 'empty' },
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadChildren: () =>
                    import('@payments').then((m) => m.LoginModule),
            },
        ],
    },
    {
        path: '',
        component: FuseLayoutComponent,
        canActivate: [AuthenticationGuard],
        children: [
            {
                path: EAppRoutes.Dashboard,
                loadChildren: () => import('./domain/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: EAppRoutes.Validators,
                loadChildren: () => import('./domain/validators/validators.module').then((m) => m.ValidatorsModule),
                canActivate: [PermissionGuard],
                data: {
                    roles: [ERoles.Technician, ERoles.DeviceManager, ERoles.CompanyAdmin],
                    breadcrumbs: [Breadcrumbs.Home, Breadcrumbs.Validators],
                },
            },
            {
                path: EAppRoutes.Pos,
                loadChildren: () => import('./domain/pos/pos.module').then((m) => m.PosModule),
                canActivate: [PermissionGuard],
                data: {
                    roles: [ERoles.DeviceManager, ERoles.CompanyAdmin],
                    breadcrumbs: [Breadcrumbs.Home, Breadcrumbs.POS],
                },
            },
            {
                path: EAppRoutes.Cards,
                loadChildren: () => import('./domain/cards/cards.module').then((m) => m.CardsModule),
                canActivate: [PermissionGuard],
                data: {
                    roles: [ERoles.CompanyAdmin, ERoles.Superuser, ERoles.PaymentsManager],
                },
            },
            {
                path: EAppRoutes.Transactions,
                loadChildren: () =>
                    import('./domain/transactions/transactions.module').then((m) => m.TransactionsModule),
                canActivate: [PermissionGuard],
                data: {
                    roles: [ERoles.CompanyAdmin, ERoles.PaymentsManager],
                    breadcrumbs: [Breadcrumbs.Home, Breadcrumbs.Transactions],
                },
            },
            {
                path: EAppRoutes.Organizations,
                loadChildren: () =>
                    import('./domain/organizations/organizations.module').then((m) => m.OrganizationsModule),
                canActivate: [PermissionGuard],
                data: {
                    roles: [ERoles.CompanyAdmin, ERoles.Superuser],
                    breadcrumbs: [Breadcrumbs.Home, Breadcrumbs.Organizations],
                },
            },
            {
                path: EAppRoutes.Settings,
                loadChildren: () => import('./domain/settings/settings.module').then((m) => m.SettingsModule),
                canActivate: [PermissionGuard],
                data: {
                    roles: [ERoles.Superuser],
                },
            },
            {
                path: EAppRoutes.Audit,
                loadChildren: () => import('./domain/audit-trail/audit-trail.module').then((m) => m.AuditTrailModule),
                canActivate: [PermissionGuard],
                data: {
                    roles: [ERoles.Superuser],
                    breadcrumbs: [Breadcrumbs.Home, Breadcrumbs.Audit],
                },
            },
            {
                path: EAppRoutes.About,
                loadChildren: () => import('./domain/about/about.module').then((m) => m.AboutModule),
                canActivate: [PermissionGuard],
                data: {
                    roles: [ERoles.Superuser],
                    breadcrumbs: [Breadcrumbs.Home, Breadcrumbs.About],
                },
            },
            {
                path: EAppRoutes.Notifications,
                loadChildren: () =>
                    import('./domain/notifications/notifications.module').then((m) => m.NotificationsModule),
                canActivate: [PermissionGuard],
                data: {
                    roles: [ERoles.Superuser, ERoles.CompanyAdmin, ERoles.DeviceManager],
                },
            },
            {
                path: '',
                redirectTo: EAppRoutes.Dashboard,
                pathMatch: 'full',
            },
            {
                path: '**',
                component: NotFoundComponent,
            },
        ],
    },
    { path: 'secured', redirectTo: '' },
    { path: '**', redirectTo: '' },
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: AuthorizedPreloadingStrategy,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
